/* UserDetails.css */
.boxstyle {
  color: #7928ca;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}

.boxstyleForm {
  margin: auto;
  border: 2px solid white;
  padding: 10px;
  border-radius: 10px;
}

.boxstyleForm1 {
  margin: auto;
  display: flex;
  align-items: left;
  justify-content: left;
  padding: 10px;
  border-radius: 10px;
}

.innerbox {
  border: 0.5px solid gray;
  border-radius: 10px;
  padding: 10px;
  width: 130px;
  margin-left: 10px;
}

.textinBox {
  margin-left: 10px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}
.basic-info-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.basic-info-container p {
  font-size: 18px;
  padding: 0 10px !important;
}
.info-icon {
  height: 30px;
  margin-right: 0px;
}
.choose-file {
  margin: 10px auto;
}
.member-join-btn {
  padding: 8px 10px !important;
  width: 160px !important;
  display: block;
  font-size: 13px !important ;
}
.reset-pass-btn {
  padding: 19px 3px !important;
  width: 160px !important;
  display: block;
  font-size: 13px !important ;
}
/* Add more styles as needed */
@media only screen and (max-width: 778px) {
  .upload-image-container {
    background-color: transporant !important;
    padding: 15px 0px;
    width: 100px !important;
    margin: 15px 0;
    border-radius: 10px;
    width: 10px;
  }
  .basic-info-container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
  .basic-info-container p {
    font-size: 10px;
    font-weight: bold;
  padding: 0  !important;

  }
  .info-icon {
    height: 18px;
  }
  .profile-image {
    height: 70px !important;
  }
  .basic-info-main-container {
    border: none;
  }
  .choose-file {
    width: 100px !important;
  }
  .upload-profile-btn {
    width: 90px !important;
    padding: 2px 10px;
  }
  .member-join-btn {
    padding: 5px 8px !important;
    width: 150px !important;
    display: block;
    display: block;
    margin: 15px auto !important;
    font-size: 11px !important ;
  }
  .reset-pass-btn {
    padding: 13px 3px !important;
    width: 150px !important;
    display: block;
    margin: 15px auto !important;
    font-size: 12px !important ;
  }
  .btn-container {
    margin-top: 1px;
    padding-left: 6px !important;
    border-bottom: 1px solid gray;
    
  }
  .basic-info-age-input{
    width: 164px;
  }
  .basic-info-age-input-container{
    display: flex;
    justify-content: start;
    align-items : start;
  }
}

.wallet-bg-container {
  width: 290px;
  background-color: #0a82ff3d !important;
  height: 230px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}
.wallet-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}
.wallet-avatar {
  /* height: 80px;
  width: 80px; */
  /* background-color: white; */
  /* border-radius: 50%; */
  /* margin: 0 auto; */
}
.wallet-avatar-img {
  display: block;
  object-fit: cover;
  border-radius: 50%;
}

.wallet-name {
  font-size: 18px;
  font-weight: bold;
}
.wallet-email {
  font-size: 16px;
  margin-top :-7px;
}
.walet-id {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);

  width: 220px;
  height: 40px;
  background-color: #0049ff;
  padding: 0;
  line-height: 40px; /* Center the text vertically within the container's height */
  text-align: center;
  border-radius: 20px;
}

@media screen and (min-width: 360px) and (max-width: 740px) {
  .wallet-bg-container {
    width: 290px;
    background-color: #0a82ff3d !important;
    height: 230px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 15px 10px;
    position: relative;
  }
  .wallet-info-container {
    margin-top: 1px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 0 2px !important;
  }
  .wallet-avatar {
    height: 80px;
    width: 80px;
    background-color: white;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
  }
  .wallet-avatar-img {
    display: block;

    object-fit: cover;
    border-radius: 50%;
  }
  .wallet-name {
    font-size: 18px;
  }
  .wallet-email {
    font-size: 16px;
  }
  .walet-id {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);

    width: 210px;
    height: 40px;
    background-color: #0049ff;
    padding: 0;
    line-height: 40px; /* Center the text vertically within the container's height */
    text-align: center;
    border-radius: 20px;
  }
}
